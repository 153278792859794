import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";

const CustomButton = (props) => {
  const styleFilled = {
    backgroundColor: "#650E3D",
    width: "100%",
    borderColor: "#650E3D",
    borderRadius: "30px",
    height: "50px",
    textTransform: "capitalize",
    fontWeight: "700",
    fontFamily: "Poppins, sans-serif",
  };
  const styleOutlined = {
    color: "#650E3D",
    width: "100%",
    border: "2px solid #650E3D",
    borderRadius: "30px",
    height: "50px",
    fontWeight: "700",
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
  };

  const style = props.variant == "contained" ? styleFilled : styleOutlined;
  const variant = props.variant == "contained" ? "contained" : "outlined";

  return (
    <Button
      variant={variant}
      className={props.className ? props.className : ""}
      disabled={props.isLoading ? true : false}
      // style={props.style}
      style={props.style ? props.style : style}
      onClick={props.onClick}
      startIcon={props.startIcon ? props.startIcon : null}
      endIcon={props.endIcon ? props.endIcon : null}
      className={props.className ? props.className : ""}
      // startAdornment={props.startIcon}
    >
      {props.isLoading && <CircularProgress size="sm" />} {props.title}
    </Button>
  );
};

export default CustomButton;
