import React from "react";
import img from "../../assets/images/image1.png";
import styles from "../../styles/General.module.css";
import { useTranslation } from "react-i18next";
const JoinUs = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.joinUs}>
      <div>
        <p>{t("join_us_text1")}</p>
        <img src={img} />
      </div>
      <div>
        <h1>{t("join_us_text2")}</h1>
        <h2>{t("join_us_text3")}</h2>
        <a href="/#register">{t("registerNow")}</a>
      </div>
    </div>
  );
};

export default JoinUs;
