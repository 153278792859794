import React from "react";
import Hero from "./Hero";
import JoinUs from "./JoinUs";
import YouLearn from "./YouLearn";
import Program from "./Program";
import WhenWhere from "./WhenWhere";
import Register from "./Register";

const Home = () => {
  return (
    <div>
      <Hero />
      <JoinUs />
      <YouLearn />
      <Program />
      <WhenWhere />
      <Register />
    </div>
  );
};

export default Home;
