import React, { useState, useLayoutEffect } from "react";
import { createBrowserHistory } from "history";

import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home";
import Layout from "./components/layout";
import "./i18n";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material";
const hist = createBrowserHistory();

function App() {
  const theme = createTheme({
    // direction: "rtl",
  });

  const Loader = () => (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter history={hist}>
        <Wrapper>
          <React.Suspense fallback={<Loader />} />
          <ToastContainer />
          <Layout>
            <ReactRoutes>
              <Route path="*" element={<Home />} />
            </ReactRoutes>
          </Layout>
        </Wrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
