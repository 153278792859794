import React from "react";

import styles from "../../styles/General.module.css";
import { useTranslation } from "react-i18next";
const WhenWhere = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.whenWhere}>
      <div>
        <h1>{t("date")}</h1>
        <span>
          <h2>{t("year")}</h2>
          <a href="/#register">{t("registerNow")}</a>
        </span>
      </div>

      <div>
        <h1>{t("place")}</h1>
        <span>
          <h2>{t("city")}</h2>
          <a target="_blank" href="https://goo.gl/maps/AmFaQKcnLKf8arp79">
            {t("googleMaps")}
          </a>
        </span>
      </div>
    </div>
  );
};

export default WhenWhere;
