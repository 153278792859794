import React from "react";
import { useMediaQuery } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Logo from "../../assets/images/7akLOGO.svg";

import facebook from "../../assets/icons/facebook.svg";
import insta from "../../assets/icons/insta.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import tiktok from "../../assets/icons/tiktok.svg";
import twitter from "../../assets/icons/twitter.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";
import youtube from "../../assets/icons/youtube.svg";

import styles from "../../styles/Layout.module.css";

import termsConditions from "../../assets/files/terms&conditions.pdf";
import privacyPolicy from "../../assets/files/privacy-policy.pdf";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = () => {
  const mobile = useMediaQuery("(max-width:600px)");

  return (
    <div className={styles.footer}>
      <img src={Logo} width={"73px"} height={"68px"} />

      <div className={styles.social}>
        {/* <a href="#" target="_blank">
          <img src={youtube} />
        </a> */}

        <a href="https://wa.me/+96176131390" target="_blank">
          <img src={whatsapp} />
        </a>

        <a href="https://www.tiktok.com/@haqq_me" target="_blank">
          <img src={tiktok} />
        </a>

        <a href="https://www.instagram.com/haqq_mena/" target="_blank">
          <img src={insta} />
        </a>

        <a href="https://twitter.com/haqqmena" target="_blank">
          <img src={twitter} />
        </a>

        <a href="https://www.facebook.com/HAQQMENA/" target="_blank">
          <img src={facebook} />
        </a>

        <a href="https://www.linkedin.com/company/haqq-me/" target="_blank">
          <img src={linkedin} />
        </a>
      </div>

      <h1>
        Contact Info: <a href="mailto:info@haqq.me">info@haqq.me</a>
      </h1>

      <h2>
        <a href={privacyPolicy} target="_blank">
          privacy policy
        </a>
        <a href={termsConditions} target="_blank">
          terms of service
        </a>
      </h2>
      <h3>© 2023 HAQQ Inc. All Rights Reserved.</h3>
    </div>
  );
};

export default Footer;
