import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header />
      <main className={i18n.language.includes("en") ? "" : "arabic_version"}>
        {" "}
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
