import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const SingleCustomAutocomplete = (props) => {
  const { t, i18n } = useTranslation();
  const filedName = props.filedName;
  const fieldLabel = props.label;
  const list = props.list;
  const fieldValue = props.value;
  const listKey = props.listKey;
  const listDescription = props.description;
  const customOnChange = props.customOnChange;

  const optionForLabel = (label) => {
    if (typeof label === "object" && !Array.isArray(label) && label !== null) {
      return label[listDescription];
    }
    let toReturn = list.find((source) => {
      return source[listKey] == label;
    });
    if (toReturn) {
      return toReturn[listDescription];
    }
    return `${label}`;
  };

  const getOptionSelected = (option, value) => {
    if (
      typeof option === "object" &&
      !Array.isArray(option) &&
      option !== null
    ) {
      return option[listKey] == value;
    }
    return false;
  };

  const update = (event, value) => {
    if (!customOnChange) {
      return;
    }
    if (value === null) {
      customOnChange(null, filedName, "", event, value);
      return;
      // }else if( typeof value === 'string'){
      //     setValue(optionForLabel(value))
    } else {
      customOnChange(
        value[listKey],
        filedName,
        value[listDescription],
        event,
        value
      );
    }
  };

  const renderOptions = (pr, option, selected) => {
    // console.log(pr,option);
    return (
      <div
        className="MuiAutocomplete-option"
        onClick={(e) => update(e, option)}
      >
        {i18n.language.includes("en") ? (
          <>
            <img src={option.flag} width={30} style={{ marginRight: "10px" }} />
            <span>{pr.key}</span>
          </>
        ) : (
          <>
            <span>{pr.key}</span>
            <img src={option.flag} width={30} style={{ marginLeft: "10px" }} />
          </>
        )}
      </div>
    );
  };

  const rtl = i18n.language.includes("en")
    ? {}
    : {
        transformOrigin: "right !important",
        left: "inherit !important",
        right: "2.8rem !important",
        overflow: "unset",

        // color: "#807D7B",
        // fontWeight: 400,
      };

  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              borderRadius: "30px",
              border: "none",
              backgroundColor: "#F4F4F4",
              height: "50px",
              fontFamily: "Poppins, sans-serif",
              "& input": {
                color: "#66023C",
                paddingLeft: "15px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                // borderColor: '#66023C',
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#66023C",
                border: "none",
              },
              "& .MuiAutocomplete-popupIndicator": {
                color: "#66023C",
              },
            },
          },

          popper: {
            "& .MuiAutocomplete-option": {
              color: "#66023C",

              justifyContent: i18n.language.includes("en")
                ? ""
                : "flex-end !important",
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            paddingRight: "20px !important",
            fontWeight: "bold",
          },
        },
      },

      MuiTextField: {
        // Add this section to override TextField styles
        styleOverrides: {
          root: {
            "& label": {
              // color: "#66023C",

              paddingLeft: "15px !important",

              color: "rgba(101, 14, 61, 0.40)",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",

              ...rtl,
            },
            "& label.Mui-focused": {
              color: "#66023C",
              // display:'none'
            },
          },
        },
      },
    },
  });

  const CustomAutocomplete = styled(Autocomplete)({});

  return (
    <FormControl style={{ width: "100%", marginTop: 10, marginBottom: 10 }}>
      <ThemeProvider theme={theme}>
        <Autocomplete
          // open={true}
          // sx={{
          //   "& .MuiAutocomplete-hasPopupIcon": {
          //     paddingRight: "0px",
          //   },
          // }}
          disabled={props.disabled ? props.disabled : false}
          name={filedName}
          options={list} // Options List
          value={fieldValue}
          onChange={update}
          getOptionLabel={optionForLabel}
          getOptionSelected={getOptionSelected}
          //   isOptionEqualToValue={getOptionSelected}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={fieldLabel}
              InputProps={{
                ...params.InputProps,
                startAdornment: null,
                endAdornment: (
                  <div>{params.InputProps.endAdornment.props.children}</div>
                ),
              }}
            />
          )}
          renderOption={
            props.renderOption == true
              ? (pr, option, { selected }) =>
                  renderOptions(pr, option, selected)
              : null
          }
        />
      </ThemeProvider>
    </FormControl>
  );
};

SingleCustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  value: PropTypes.any,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any,
  disabled: PropTypes.any,
};

export default SingleCustomAutocomplete;
