import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import logo from "../../assets/images/7akLOGO.svg";
import styles from "../../styles/Layout.module.css";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();

  const drawerWidth = 240;

  const navItems = [{ id: 0, name: "", route: "/" }];

  const [mobileOpen, setMobileOpen] = React.useState(false);

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
  //     <Typography variant="h6" sx={{ my: 2 }} component={Link} to={"/"}>
  //       <img
  //         src={logo}
  //         width="125"
  //         style={{ marginTop: 10, marginBottom: 10 }}
  //       />
  //     </Typography>
  //     <Divider />
  //     <List>
  //       {navItems.map((item, index) => (
  //         <ListItem key={index} disablePadding>
  //           <ListItemButton
  //             className={styles.link}
  //             sx={{ textAlign: "center" }}
  //             component={Link}
  //             to={item.route}
  //           >
  //             <ListItemText primary={item.name} />
  //           </ListItemButton>
  //           {/* )} */}
  //         </ListItem>
  //       ))}
  //     </List>

  //     <a className={styles.registerButton} href="/contact">
  //       Register Now
  //     </a>
  //   </Box>
  // );

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [top, setTop] = useState(true);
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY < 200) {
        setTop(true);
      } else {
        setTop(false);
      }
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        style={{
          boxShadow: "none",
          marginTop: !show && "-130px",
          transition: "all 1s",
          backgroundColor: top ? "transparent" : "#650e3d",
          boxShadow: top ? "none" : "0px 5px 30px 0px  rgba(0, 0, 0, 0.5)",
        }}
      >
        <Toolbar
          style={{ display: "flex", marginTop: "20px" }}
          sx={{
            justifyContent: "space-between",
            paddingInline: { sm: "0px", md: "100px" },
          }}
        >
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "none" },
              color: "black",
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Button
            style={{ width: "125px" }}
            variant="h6"
            component={Link}
            to="/"
            sx={{
              width: 125,
              padding: 0,
              display: "block",
            }}
          >
            <img src={logo} width="50" />
          </Button>
          <Box
            sx={{ display: "flex" }}
            style={{
              justifyContent: "space-around",
            }}
          >
            {navItems.map((item, index) => (
              <Button className={styles.link} key={index}>
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  href={item.route}
                >
                  {item.name}
                </a>
              </Button>
            ))}
          </Box>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a className={styles.registerButton} href="/#register">
              {t("registerNow")}
            </a>
            <a
              href="#"
              onClick={() =>
                i18n.changeLanguage(i18n.language.includes("en") ? "ar" : "en")
              }
              style={{
                marginLeft: "20px",
                textDecoration: "none",
                color: "White",
                textTransform: "uppercase",
              }}
            >
              {i18n.language.includes("en") ? "ar" : "en"}
              {/* ; */}
            </a>
          </div>
        </Toolbar>
      </AppBar>
      {/* <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box> */}
    </Box>
  );
};

export default Header;
