import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CircularProgress, Grid } from "@mui/material";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomButton from "src/components/Custom/CustomButton";
import {
  CustomCheckbox,
  CustomInput,
  CustomRadio,
} from "src/components/Custom/CustomInputForms";
import SingleCustomAutocomplete from "src/components/Custom/SingleCustomAutocomplete";
import Frame from "../../assets/icons/logo.svg";
import styles from "../../styles/General.module.css";
import CustomToast from "src/components/Custom/CustomToast";
import handleErrors from "src/components/helpers/handleErrors";
import axios from "axios";
import { useTranslation } from "react-i18next";
import termsPrivacy from "../../assets/files/terms&privacy.pdf";
const Register = () => {
  const { t, i18n } = useTranslation();

  const CustomForm = ({ status, message, onValidated }) => {
    const en = i18n.language.includes("en");
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [prefixList, setPrefixList] = useState([]);
    const [titles, setTitles] = useState([]);
    const [formData, setFormData] = useState({
      serial: -1,
      first_name: "",
      middle_name: "",
      last_name: "",
      title: null,
      phone_prefix: "",
      phone: "",
      nationality: null,
      location: null,
      email: "",
      password: "",
      password_confirmation: "",
      is_valid: false,
      type: "lawyer",

      organisation: "",
      position: "",
      attending: "both",
    });
    // const titles = [
    //   {
    //     id: "Mr",
    //     label: "Mr",
    //   },
    //   {
    //     id: "Ms",
    //     label: "Ms",
    //   },
    // ];
    const resetForm = () => {
      setFormData({
        serial: -1,
        first_name: "",
        middle_name: "",
        last_name: "",
        title: null,
        phone_prefix: "",
        phone: "",
        nationality: null,
        location: null,
        email: "",
        password: "",
        password_confirmation: "",
        is_valid: false,
        type: "lawyer",

        organisation: "",
        position: "",
        attending: "both",
      });
    };

    const customOnChange = (newValue, name) => {
      setFormData((prev) => ({
        ...prev,
        [name]: newValue,
      }));
    };

    const handleInputChange = (event) => {
      const { id, value, checked, type, name } = event.target;

      if (type == "checkbox") {
        setFormData((prev) => ({
          ...prev,
          [id]: checked,
        }));
      } else if (type == "radio") {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [id]: value,
        }));
      }
    };

    useEffect(() => {
      if (countriesList.length == 0) {
        const getCountries = () => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/masterData/countries/get`,
              {
                params: {},
              }
            )
            .then(function (response) {
              setCountriesList(response.data.data);
              setPrefixList(response.data.prefix);
            })
            .catch(function (error) {
              // handleErrors(error);
            });
        };
        getCountries();
      }

      if (titles.length == 0) {
        const getTitles = () => {
          axios
            .get(
              `${process.env.REACT_APP_API_BASE_URL}/masterData/titles/get`,
              {
                params: {},
              }
            )
            .then(function (response) {
              setTitles(response.data);
            })
            .catch(function (error) {
              // handleErrors(error);
            });
        };
        getTitles();
      }
    }, []);

    useEffect(() => {
      countriesList.forEach((e) => {
        if (e.id == formData.location) {
          setFormData({
            ...formData,
            phone_prefix: e.phone_prefix,
          });
        }
      });
    }, [formData.location]);

    const saveForm = () => {
      formData.email &&
        formData.first_name &&
        formData.middle_name &&
        formData.last_name &&
        onValidated({
          EMAIL: formData.email,
          NAME: `${formData.first_name} ${formData.middle_name} ${formData.last_name}`,
        });

      let msg = en
        ? "“Your registration has been successfully processed, please check your email. See you at Lawyers of Tomorrow!”"
        : "تم تسجيل حضوركم بنجاح. نراكم في المؤتمر!";
      CustomToast({
        type: "success",
        message: msg,
      });
      resetForm();
      return;
      setSaveIsLoading(true);
      if (formData.is_valid == true) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}/auth/signup`,
          data: formData,
        })
          .then((response) => {
            resetForm();
            setSaveIsLoading(false);
            CustomToast({
              type: "success",
              message: "Registered successfully",
            });

            onValidated({
              EMAIL: formData.email,
              NAME: `${formData.first_name} ${formData.middle_name} ${formData.last_name}`,
            });
          })
          .catch((error) => {
            setSaveIsLoading(false);
            // handleErrors(error);
          });
      } else {
        CustomToast({
          type: "warning",
          message: "Please confirm your intent to attend",
        });
        setSaveIsLoading(false);
      }
    };

    return (
      <div className={styles.form} id="register">
        <h1>{t("register_text3")}</h1>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={12} md={12}>
            <CustomRadio
              onChange={handleInputChange}
              value={formData.type}
              label={t("legal?")}
              name="type"
              id="type"
              defaultValue="lawyer"
              options={[
                {
                  label: t("no"),
                  value: "client",
                },
                {
                  label: t("yes"),
                  value: "lawyer",
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <SingleCustomAutocomplete
              filedName="title"
              label={t("title")}
              list={titles}
              value={formData.title}
              listKey="id"
              description={en ? "name" : "name_ar"}
              customOnChange={customOnChange}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomInput
              id={"first_name"}
              value={formData.first_name}
              onChange={handleInputChange}
              placeholder={t("first_name")}
              withBorder={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              id={"middle_name"}
              value={formData.middle_name}
              onChange={handleInputChange}
              placeholder={t("middle_name")}
              withBorder={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              id={"last_name"}
              value={formData.last_name}
              onChange={handleInputChange}
              placeholder={t("last_name")}
              withBorder={false}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomInput
              id={"email"}
              value={formData.email}
              onChange={handleInputChange}
              placeholder={t("email")}
              withBorder={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleCustomAutocomplete
              filedName="nationality"
              label={t("nationality")}
              list={countriesList}
              value={formData.nationality}
              listKey="id"
              description="name"
              customOnChange={customOnChange}
              renderOption={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleCustomAutocomplete
              filedName="location"
              label={t("location")}
              list={countriesList}
              value={formData.location}
              listKey="id"
              description="name"
              customOnChange={customOnChange}
              renderOption={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SingleCustomAutocomplete
              filedName="phone_prefix"
              label={t("prefix")}
              list={prefixList}
              value={formData.phone_prefix}
              listKey="phone_prefix"
              description="phone_prefix"
              customOnChange={customOnChange}
              renderOption={true}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomInput
              id={"phone"}
              value={formData.phone}
              onChange={handleInputChange}
              placeholder={t("phone")}
              withBorder={false}
              // type={'number'}
            />
          </Grid>

          {/* <Grid item xs={12} md={5}>
        <CustomInput
          id={"password"}
          value={formData.password}
          onChange={handleInputChange}
          placeholder="Password"
          withBorder={false}
          type={inputType == true ? "password" : "text"}
          endAdornment={
            <VisibilityIcon
              style={{ cursor: "pointer", color: "#650E3D" }}
              onClick={() => setInputType(!inputType)}
            />
          }
        />
      </Grid> */}
          {/* <Grid item xs={8} md={5}>
        <CustomInput
          id={"password_confirmation"}
          value={formData.password_confirmation}
          onChange={handleInputChange}
          placeholder="Confirm Password"
          withBorder={false}
          type={inputType2 == true ? "password" : "text"}
          endAdornment={
            <VisibilityIcon
              style={{ cursor: "pointer", color: "#650E3D" }}
              onClick={() => setInputType2(!inputType2)}
            />
          }
        />
      </Grid> */}

          <Grid item xs={12} md={6}>
            <CustomInput
              id={"organisation"}
              value={formData.organisation}
              onChange={handleInputChange}
              placeholder={t("firm")}
              withBorder={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              id={"position"}
              value={formData.position}
              onChange={handleInputChange}
              placeholder={t("position")}
              withBorder={false}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <CustomRadio
              onChange={handleInputChange}
              value={formData.attending}
              label={t("attending?")}
              name="attending"
              id="attending"
              defaultValue="both"
              options={[
                {
                  label: t("in_person"),
                  value: "in_person",
                },
                {
                  label: t("virtually"),
                  value: "virtually",
                },

                {
                  label: t("both"),
                  value: "both",
                },
              ]}
            />
          </Grid>
          {/* <Grid item xs={4} md={2}>
        <div className={styles.small_text}>
          Please Use a combination of letters, numbers, and special
          characters
        </div>
      </Grid> */}
          <Grid item xs={12} md={6}>
            <CustomCheckbox
              label={t("confirm_text")}
              checked={formData.is_valid}
              id="is_valid"
              onClick={handleInputChange}
            />
            <br />
            <a
              style={{
                textDecoration: "none",
                color: "#650E3D",
                fontSize: "12px",
                marginInline: 30,
              }}
              target="_blank"
              href={termsPrivacy}
            >
              Terms & Privacy
            </a>
          </Grid>
          {/* <Grid item xs={10} md={6}>
        <div className={styles.medium_text}>{t("confirm_text")}</div>
      </Grid> */}
          <Grid item xs={12} md={6}>
            {/* {status === "sending" && <CircularProgress color="secondary" />}
            {status === "error" && (
              <div
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "success" && (
              <div
                style={{ color: "green" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )} */}

            {/* {!status && ( */}
            <CustomButton
              variant="contained"
              title={t("registerNow")}
              onClick={saveForm}
              isLoading={saveIsLoading}
            />
            {/* )} */}
          </Grid>
        </Grid>
      </div>
    );
  };
  return (
    <div className={styles.register}>
      <h1>
        {t("register_text1")}
        <br />
        <span>{t("register_text2")}</span>
      </h1>
      <MailchimpSubscribe
        url={process.env.REACT_APP_MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default Register;
