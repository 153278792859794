import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "../../styles/General.module.css";
import { useMediaQuery } from "@mui/material";

import RightArrow from "../../assets/icons/rightArrow.svg";
import LeftArrow from "../../assets/icons/lefArrow.svg";
import { useRef } from "react";

const CustomCarousel = (props) => {
  const mobile = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:800px)");
  const sliderRef = useRef();
  const settings = {
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    // centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      props?.data?.length < 2
        ? 1
        : props?.data?.length < 3
        ? 2
        : props?.slidesToShow
        ? props?.slidesToShow
        : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: props.slidesToShow ?? 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        position: "relative",
        marginTop: "max(calc(100px / 2), calc((5.8vw * 100) / 100))",
      }}
    >
      <span
        className={styles.arrowLeft}
        onClick={() => sliderRef?.current.slickPrev()}
      >
        <img src={LeftArrow} />
      </span>

      <Slider ref={sliderRef} {...settings} arrows={false} style={{}}>
        {props?.data?.map((rec) => (
          <div className={styles.carouselCard}>
            <h1>{props.en ? rec.title : rec.ar_title}</h1>
            <p>{props.en ? rec.description : rec.ar_description}</p>
          </div>
        ))}
      </Slider>
      <span
        className={styles.arrowRight}
        onClick={() => sliderRef?.current.slickNext()}
      >
        <img src={RightArrow} />
      </span>
    </div>
  );
};

export default CustomCarousel;
