import * as React from "react";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import styles from "../../styles/General.module.css";
import { useTranslation } from "react-i18next";

const Program = () => {
  const { t, i18n } = useTranslation();
  const en = i18n.language.includes("en");
  const steps = [
    {
      label: `09:00 AM`,
      ar_label: "٠٩:٠٠ ص",
      description: "DOORS OPEN",
      ar_description: "إستقبال",
    },
    {
      label: "09:30 AM",
      ar_label: "٠٩:٣٠ ص",
      description: "Officiating Ceremony & MyHAQQ Launch",
      ar_description: "حفل إفتتاح",
    },
    {
      label: "10:00 AM",
      ar_label: "١٠:٠٠ ص",
      description: "PANEL 1 ",
      ar_description: "لجنة ١",
      extra: "NAVIGATING AI REGULATION",
      ar_extra: " اسلوب تنظيم الذكاء الاصطناعي",
    },

    {
      label: "11:00 AM",
      ar_label: "١١:٠٠ ص",
      description: "NETWORKING BREAK",
      ar_description: "إستراحة",
    },

    {
      label: "11:50 AM",
      ar_label: "١١:٥٠ ص",
      description: "PANEL 2 ",
      ar_description: "لجنة ٢",
      extra: "FUTURE-PROOFING LAWYERS",
      ar_extra: " تمكين محامين الغد",
    },
    {
      label: "12:50 PM",
      ar_label: "١٢:٥٠ م",
      description: "FAREWELL",
      ar_description: "الوداع",
    },
  ];
  const theme = createTheme({
    components: {
      // Name of the component
      MuiStepper: {
        styleOverrides: {
          // Name of the slot
          root: {},
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            textAlign: en ? "" : "right",
            marginRight: en ? "" : "20px",
          },
        },
      },

      MuiStepIcon: {
        styleOverrides: {
          // Name of the slot
          root: {
            color: "#650E3D !important",
          },
          text: {
            fill: "#650E3D",
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          // Name of the slot
          root: {
            borderLeft: en ? "1px solid #bdbdbd" : "0px",
            borderRight: en ? "0px" : "1px solid #bdbdbd",
            marginRight: en ? "" : "18px",
            paddingRight: en ? "" : "40px",
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          // Name of the slot
          root: {
            "& span": {
              borderLeft: en ? "1px solid #bdbdbd" : "0px",
              borderRight: en ? "0px" : "1px solid #bdbdbd",
              marginRight: en ? "" : "18px",
            },
          },
        },
      },
    },
  });
  return (
    <div className={styles.program}>
      <div className={styles.programTitle}>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="103"
          height="728"
          viewBox="0 0 103 728"
          fill="none"
        >
          <path
            d="M29.3464 667.287C38.2654 667.287 45.2184 670.049 50.2053 675.572C55.0964 681.096 57.5419 689.286 57.5419 700.143L57.5419 721.572H101.849V728H0.863142L0.86314 700.143C0.86314 689.096 3.45253 680.858 8.6313 675.43C13.7142 670.001 20.6192 667.287 29.3464 667.287ZM51.7877 700.143C51.7877 682.525 44.3073 673.715 29.3464 673.715C21.9618 673.715 16.3515 675.81 12.5154 680.001C8.58334 684.191 6.61732 690.906 6.61732 700.143L6.61733 721.572H51.7877L51.7877 700.143Z"
            fill="#650E3D"
          />
          <path
            d="M101.849 582.303L58.8366 608.017L58.8366 630.016H101.849V636.445H0.863138L0.863136 608.874C0.863136 597.731 3.50047 589.398 8.77515 583.874C14.0498 578.255 21.0508 575.446 29.7779 575.446C37.5461 575.446 43.9716 577.636 49.0545 582.017C54.0414 586.303 57.1103 592.588 58.2612 600.874L101.849 574.589V582.303ZM53.3701 630.016L53.3701 608.588C53.3701 599.731 51.2123 593.112 46.8967 588.731C42.581 584.351 36.8748 582.16 29.7779 582.16C14.3375 582.16 6.61732 591.065 6.61732 608.874L6.61732 630.016H53.3701Z"
            fill="#650E3D"
          />
          <path
            d="M103 498.083C103 507.321 100.842 515.655 96.5266 523.083C92.115 530.511 85.9772 536.321 78.1131 540.511C70.2491 544.702 61.378 546.797 51.5 546.797C41.5261 546.797 32.655 544.702 24.8869 540.511C17.0228 536.321 10.933 530.511 6.61732 523.083C2.20578 515.655 5.97184e-06 507.321 5.56521e-06 498.083C5.15858e-06 488.846 2.20577 480.512 6.61731 473.084C10.933 465.656 17.0228 459.846 24.8869 455.656C32.655 451.465 41.5261 449.37 51.5 449.37C61.378 449.37 70.2491 451.465 78.1131 455.656C85.9772 459.846 92.115 465.656 96.5266 473.084C100.842 480.512 103 488.846 103 498.083ZM97.2458 498.083C97.2458 490.179 95.3757 483.036 91.6355 476.655C87.8953 470.274 82.5726 465.275 75.6676 461.656C68.7626 457.941 60.7067 456.084 51.5 456.084C42.1974 456.084 34.0936 457.941 27.1885 461.656C20.2835 465.275 14.9609 470.274 11.2207 476.655C7.48045 483.036 5.61034 490.179 5.61034 498.083C5.61034 505.988 7.48045 513.131 11.2207 519.512C14.9609 525.893 20.2835 530.94 27.1885 534.654C34.0936 538.368 42.1974 540.226 51.5 540.226C60.7067 540.226 68.7626 538.368 75.6676 534.654C82.5726 530.94 87.8953 525.893 91.6355 519.512C95.3757 513.131 97.2458 505.988 97.2458 498.083Z"
            fill="#650E3D"
          />
          <path
            d="M30.6411 333.938C23.0647 336.795 17.0228 341.557 12.5154 348.224C8.00791 354.795 5.75418 362.604 5.75418 371.652C5.75418 379.556 7.6243 386.699 11.3645 393.08C15.1047 399.461 20.4274 404.508 27.3324 408.222C34.2374 411.937 42.2933 413.794 51.5 413.794C60.7067 413.794 68.7626 411.937 75.6676 408.222C82.5726 404.508 87.8953 399.461 91.6355 393.08C95.2798 386.699 97.102 379.556 97.102 371.652C97.102 364.033 95.3757 357.128 91.9232 350.938C88.4707 344.747 83.5796 339.795 77.25 336.081C70.8245 332.367 63.344 330.319 54.8087 329.938V374.652H49.3422V323.367H53.6578C63.0563 323.653 71.4958 325.891 78.9763 330.081C86.4567 334.271 92.3068 339.986 96.5265 347.224C100.746 354.461 102.856 362.604 102.856 371.652C102.856 380.89 100.698 389.223 96.3827 396.651C91.9711 404.08 85.8813 409.889 78.1131 414.079C70.2491 418.27 61.378 420.365 51.5 420.365C41.5261 420.365 32.655 418.27 24.8869 414.079C17.0228 409.889 10.933 404.08 6.61731 396.651C2.20577 389.223 4.0663e-07 380.89 0 371.652C-4.73703e-07 360.89 2.73324 351.604 8.19972 343.795C13.5703 335.89 21.0507 330.129 30.6411 326.51V333.938Z"
            fill="#650E3D"
          />
          <path
            d="M101.849 236.21L58.8366 261.924V283.923H101.849V290.352H0.863122L0.863121 262.781C0.863121 251.638 3.50046 243.305 8.77513 237.781C14.0498 232.162 21.0507 229.353 29.7779 229.353C37.5461 229.353 43.9716 231.543 49.0545 235.924C54.0414 240.21 57.1103 246.495 58.2612 254.781L101.849 228.496V236.21ZM53.3701 283.923V262.495C53.3701 253.638 51.2123 247.019 46.8966 242.638C42.581 238.258 36.8748 236.067 29.7779 236.067C14.3375 236.067 6.6173 244.972 6.61731 262.781L6.61731 283.923H53.3701Z"
            fill="#650E3D"
          />
          <path
            d="M75.9553 139.848V187.418L101.849 196.99V203.99L3.16479 167.133L3.16479 159.99L101.849 123.277V130.277L75.9553 139.848ZM70.4888 141.848L11.6522 163.562L70.4888 185.418L70.4888 141.848Z"
            fill="#650E3D"
          />
          <path
            d="M3.02093 4.28988e-06L101.849 0V6.42846L16.5433 6.42846L101.849 44.142V49.2848L16.5433 87.1413L101.849 87.1413V93.5697L3.02093 93.5697L3.02093 86.7127L93.3617 46.7134L3.02093 6.71417L3.02093 4.28988e-06Z"
            fill="#650E3D"
          />
        </svg> */}
        <h1>{t("program")}</h1>
      </div>
      <ThemeProvider theme={theme}>
        <Stepper orientation="vertical">
          {steps.map((step, index) => (
            <Step active={true} key={step.label}>
              <StepLabel>{en ? step.label : step.ar_label}</StepLabel>
              <StepContent>
                <Typography>
                  {en ? step.description : step.ar_description}
                  <span>{en ? step.extra : step.ar_extra}</span>
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </ThemeProvider>
    </div>
  );
};

export default Program;
