import React, { useEffect, useState } from "react";
import styles from "../../styles/General.module.css";
import { useTranslation } from "react-i18next";
const Hero = () => {
  const { t, i18n } = useTranslation();
  const [countdownDate, setCountdownDate] = useState(
    new Date("9/01/2023 21:30:00").getTime()
  );
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    setInterval(() => setNewTime(), 1000);
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      if (numbersToAddZeroTo.includes(days)) {
        days = `0${days}`;
      }

      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      }
      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }
      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({ days: days, hours: hours, minutes, seconds });
    }
  };

  return (
    <div className={styles.hero}>
      <div>
        <div className={styles.countdown_wrapper} dir="ltr">
          <div className={styles.time_section}>
            <small className={styles.time_text}>{t("days")}</small>
            <div className={styles.time}>{state.days || "0"}</div>
          </div>
          <div className={styles.time_section}>
            <div className={styles.time}>:</div>
          </div>
          <div className={styles.time_section}>
            <small className={styles.time_text}>{t("hours")}</small>
            <div className={styles.time}>{state.hours || "00"}</div>
          </div>
          <div className={styles.time_section}>
            <div className={styles.time}>:</div>
          </div>
          <div className={styles.time_section}>
            <small className={styles.time_text}>{t("minutes")}</small>
            <div className={styles.time}>{state.minutes || "00"}</div>
          </div>
          <div className={styles.time_section}>
            <div className={styles.time}>:</div>
          </div>
          <div className={styles.time_section}>
            <small className={styles.time_text}>{t("seconds")}</small>
            <div className={styles.time}>{state.seconds || "00"}</div>
          </div>
        </div>

        <h1>
          {t("hero_text1")} <br />
          <span>{t("hero_text2")}</span>
        </h1>
        <h2>{t("hero_text3")}</h2>
        <h2>{t("hero_text4")}</h2>
        <a href="/#register">{t("registerNow")}!</a>
      </div>
    </div>
  );
};

export default Hero;
