import {
  FormControl,
  styled,
  createTheme,
  ThemeProvider,
  Input,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ToggleButtonGroup,
  ToggleButton,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export const CustomInput = (props) => {
  const { t, i18n } = useTranslation();

  const inputEnglishStyle = {
    paddingLeft: "30px", // Adjust the padding-left value as needed
    textAlign: "left", // Set the desired text alignment
    fontWeight: "bold",
    color: "#66023C",
    height: "18px",
    fontFamily: "Poppins, sans-serif",
  };
  const inputArabicStyle = {
    paddingRight: "30px", // Adjust the padding-left value as needed
    fontWeight: "bold",
    color: "#66023C",
    direction: "rtl",
    textAlign: "right",
    height: "18px",
    fontFamily: "Poppins, sans-serif",
  };

  const inputStyle =
    i18n.language === "ar" ? inputArabicStyle : inputEnglishStyle;
  const CustomThemeOutlinedInputWithout = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWithout = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      backgroundColor: "#F8F8F8",
      // paddingRight: "30px", // Adjust the padding-left value as needed
      fontWeight: "bold",
      color: "#66023C",
      textAlign: "right",
      minHeight: "50px",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const CustomThemeOutlinedInputWith = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWith = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      // backgroundColor: "#FFFFFF",
      backgroundColor: "#F8F8F8",
      height: "50px",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const theme = createTheme(
    props.withBorder == true
      ? { CustomThemeOutlinedInputWith }
      : { CustomThemeOutlinedInputWithout }
  );

  return (
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      {props.fieldLabel ? (
        <InputLabel style={{ color: "#66023C" }}>{props.fieldLabel}</InputLabel>
      ) : null}
      <ThemeProvider theme={theme}>
        <OutlinedInput
          id={props.id}
          name={props.id}
          multiline={props.multiline ? true : false}
          minRows={props.minRows}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          type={props.type ? props.type : "text"}
          disabled={props.disabled ? true : false}
          placeholder={props.placeholder ? props.placeholder : ""}
          startAdornment={
            i18n.language === "ar" ? (
              props.endAdornment ? (
                <InputAdornment position="start">
                  {props.endAdornment}
                </InputAdornment>
              ) : null
            ) : props.startAdornment ? (
              <InputAdornment position="start">
                {props.startAdornment}
              </InputAdornment>
            ) : null
          }
          endAdornment={
            i18n.language === "ar" ? (
              props.startAdornment ? (
                <InputAdornment position="end">
                  {props.startAdornment}
                </InputAdornment>
              ) : null
            ) : props.endAdornment ? (
              <InputAdornment position="end">
                {props.endAdornment}
              </InputAdornment>
            ) : null
          }
          onWheel={(e) => {
            e.target.blur();
          }}
          sx={
            props.withBorder == true
              ? CustomOutlinedInputWith
              : CustomOutlinedInputWithout
          }
        />
      </ThemeProvider>
    </FormControl>
  );
};

export const CustomTextArea = (props) => {
  const { t, i18n } = useTranslation();

  const inputEnglishStyle = {
    paddingLeft: "30px", // Adjust the padding-left value as needed
    textAlign: "left", // Set the desired text alignment
    fontWeight: "bold",
    color: "#66023C",
    height: "18px",
    fontFamily: "Poppins, sans-serif",
  };
  const inputArabicStyle = {
    paddingRight: "30px", // Adjust the padding-left value as needed
    fontWeight: "bold",
    color: "#66023C",
    direction: "rtl",
    textAlign: "right",
    height: "18px",
    fontFamily: "Poppins, sans-serif",
  };

  const inputStyle =
    i18n.language === "ar" ? inputArabicStyle : inputEnglishStyle;
  const CustomThemeOutlinedInputWithout = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWithout = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      backgroundColor: "#F8F8F8",
      paddingRight: "30px", // Adjust the padding-left value as needed
      fontWeight: "bold",
      color: "#66023C",
      textAlign: "right",
      minHeight: "50px",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const CustomThemeOutlinedInputWith = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWith = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      backgroundColor: "#FFFFFF",
      height: "50px",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const theme = createTheme(
    props.withBorder == true
      ? { CustomThemeOutlinedInputWith }
      : { CustomThemeOutlinedInputWithout }
  );

  return (
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      {props.fieldLabel ? (
        <InputLabel style={{ color: "#66023C" }}>{props.fieldLabel}</InputLabel>
      ) : null}
      <ThemeProvider theme={theme}>
        <OutlinedInput
          id={props.id}
          name={props.id}
          multiline={props.multiline ? true : false}
          minRows={props.minRows ?? 3}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          type={props.type ? props.type : "text"}
          disabled={props.disabled ? true : false}
          placeholder={props.placeholder ? props.placeholder : ""}
          startAdornment={
            i18n.language === "ar" ? (
              props.endAdornment ? (
                <InputAdornment position="start">
                  {props.endAdornment}
                </InputAdornment>
              ) : null
            ) : props.startAdornment ? (
              <InputAdornment position="start">
                {props.startAdornment}
              </InputAdornment>
            ) : null
          }
          endAdornment={
            i18n.language === "ar" ? (
              props.startAdornment ? (
                <InputAdornment position="end">
                  {props.startAdornment}
                </InputAdornment>
              ) : null
            ) : props.endAdornment ? (
              <InputAdornment position="end">
                {props.endAdornment}
              </InputAdornment>
            ) : null
          }
          onWheel={(e) => {
            e.target.blur();
          }}
          sx={
            props.withBorder == true
              ? CustomOutlinedInputWith
              : CustomOutlinedInputWithout
          }
        />
      </ThemeProvider>
    </FormControl>
  );
};

export const CustomSearch = (props) => {
  const { t, i18n } = useTranslation();

  const inputEnglishStyle = {
    paddingLeft: "30px", // Adjust the padding-left value as needed
    textAlign: "left", // Set the desired text alignment
    fontWeight: "bold",
    color: "#66023C",
    fontFamily: "Poppins, sans-serif",
  };
  const inputArabicStyle = {
    paddingRight: "30px", // Adjust the padding-left value as needed
    fontWeight: "bold",
    color: "#66023C",
    direction: "rtl",
    textAlign: "right",
    fontFamily: "Poppins, sans-serif",
  };

  const inputStyle =
    i18n.language === "ar" ? inputArabicStyle : inputEnglishStyle;
  const CustomThemeOutlinedInputWithout = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "20px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWithout = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      backgroundColor: "white",
      height: "40px",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const CustomThemeOutlinedInputWith = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWith = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      backgroundColor: "#FFFFFF",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const theme = createTheme(
    props.withBorder == true
      ? { CustomThemeOutlinedInputWith }
      : { CustomThemeOutlinedInputWithout }
  );

  return (
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      {props.fieldLabel ? (
        <InputLabel style={{ color: "#66023C" }}>{props.fieldLabel}</InputLabel>
      ) : null}
      <ThemeProvider theme={theme}>
        <OutlinedInput
          id={props.id}
          name={props.id}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          type={props.type ? props.type : "text"}
          disabled={props.disabled ? true : false}
          placeholder={props.placeholder ? props.placeholder : ""}
          startAdornment={
            i18n.language === "ar" ? (
              props.endAdornment ? (
                <InputAdornment position="start">
                  {props.endAdornment}
                </InputAdornment>
              ) : null
            ) : props.startAdornment ? (
              <InputAdornment position="start">
                {props.startAdornment}
              </InputAdornment>
            ) : null
          }
          endAdornment={
            i18n.language === "ar" ? (
              props.startAdornment ? (
                <InputAdornment position="end">
                  {props.startAdornment}
                </InputAdornment>
              ) : null
            ) : props.endAdornment ? (
              <InputAdornment position="end">
                {props.endAdornment}
              </InputAdornment>
            ) : null
          }
          onWheel={(e) => {
            e.target.blur();
          }}
          sx={
            props.withBorder == true
              ? CustomOutlinedInputWith
              : CustomOutlinedInputWithout
          }
        />
      </ThemeProvider>
    </FormControl>
  );
};

export const CustomBoxInput = (props) => {
  const { t, i18n } = useTranslation();

  const inputEnglishStyle = {
    paddingLeft: "30px", // Adjust the padding-left value as needed
    textAlign: "left", // Set the desired text alignment
    fontWeight: "bold",
    color: "#66023C",
    fontFamily: "Poppins, sans-serif",
  };
  const inputArabicStyle = {
    paddingRight: "30px", // Adjust the padding-left value as needed
    fontWeight: "bold",
    color: "#66023C",
    direction: "rtl",
    textAlign: "right",
    fontFamily: "Poppins, sans-serif",
  };

  const inputStyle =
    i18n.language === "ar" ? inputArabicStyle : inputEnglishStyle;
  const CustomThemeOutlinedInputWithout = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "50px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWithout = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "5px", // Set the desired border radius value
      backgroundColor: "white",
      height: "50px",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const CustomThemeOutlinedInputWith = {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C",
            },
            "& input::-webkit-input-placeholder": {
              color: "#66023C",
            },
            "& input::-moz-placeholder": {
              color: "#66023C",
            },
            "& input:-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::-ms-input-placeholder": {
              color: "#66023C",
            },
            "& input::placeholder": {
              color: "#66023C",
            },
          },
        },
      },
    },
  };
  const CustomOutlinedInputWith = {
    "&.MuiOutlinedInput-root": {
      borderColor: "#66023C",
      borderRadius: "30px", // Set the desired border radius value
      backgroundColor: "#F8F8F8",
    },
    "&.Mui-focused.MuiOutlinedInput-root": {
      borderColor: "#66023C",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#66023C",
    },
    "& input::-webkit-input-placeholder": {
      color: "#66023C",
    },
    "& input::-moz-placeholder": {
      color: "#66023C",
    },
    "& input:-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input::-ms-input-placeholder": {
      color: "#66023C",
    },
    "& input": inputStyle,
  };

  const theme = createTheme(
    props.withBorder == true
      ? { CustomThemeOutlinedInputWith }
      : { CustomThemeOutlinedInputWithout }
  );

  return (
    <FormControl fullWidth={true} style={{ marginTop: 10, marginBottom: 10 }}>
      {props.fieldLabel ? (
        <InputLabel style={{ color: "#66023C" }}>{props.fieldLabel}</InputLabel>
      ) : null}
      <ThemeProvider theme={theme}>
        <OutlinedInput
          id={props.id}
          name={props.id}
          value={props.value}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onKeyDown={props.onKeyDown}
          type={props.type ? props.type : "text"}
          disabled={props.disabled ? true : false}
          placeholder={props.placeholder ? props.placeholder : ""}
          startAdornment={
            i18n.language === "ar" ? (
              props.endAdornment ? (
                <InputAdornment position="start">
                  {props.endAdornment}
                </InputAdornment>
              ) : null
            ) : props.startAdornment ? (
              <InputAdornment position="start">
                {props.startAdornment}
              </InputAdornment>
            ) : null
          }
          endAdornment={
            i18n.language === "ar" ? (
              props.startAdornment ? (
                <InputAdornment position="end">
                  {props.startAdornment}
                </InputAdornment>
              ) : null
            ) : props.endAdornment ? (
              <InputAdornment position="end">
                {props.endAdornment}
              </InputAdornment>
            ) : null
          }
          onWheel={(e) => {
            e.target.blur();
          }}
          sx={
            props.withBorder == true
              ? CustomOutlinedInputWith
              : CustomOutlinedInputWithout
          }
        />
      </ThemeProvider>
    </FormControl>
  );
};

export const CustomCheckbox = (props) => {
  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#66023C", // Replace with your custom color code
            "&.Mui-checked": {
              color: "#66023C", // Replace with your custom color code
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#66023C",
            color: "#650E3D",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
          },
        },
      },
    },
  });
  return (
    <FormControl>
      <ThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.checked}
              id={props.id}
              name={props.id}
              tabIndex={-1}
              onClick={props.onClick}
              disabled={props.disabled === true ? true : false}
            />
          }
          label={props.label}
        />
      </ThemeProvider>
    </FormControl>
  );
};

export const InLineCustomCheckbox = (props) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.checked}
            id={props.id}
            name={props.id}
            onClick={props.onClick}
            disabled={props.disabled === true ? true : false}
          />
        }
        label={props.label}
      />
    </FormGroup>
  );
};

export const CustomToggle = (props) => {
  const theme = createTheme({
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: "50px",
            width: "170px",
            height: "45px",
            "&.Mui-selected": {
              backgroundColor: "#650E3D",
              color: "white",
            },
            border: "1px solid #650E3D",
            color: "#650E3D",
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            "&:not(:last-of-type)": {
              borderRadius: "50px",
            },
            "&:not(:first-of-type)": {
              borderRadius: "50px",
              marginLeft: "-30px",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ToggleButtonGroup
        value={props.value}
        exclusive
        size="small"
        onChange={props.onChange}
      >
        {props.options.map((v, index) => {
          return (
            <ToggleButton
              key={index}
              value={v.id}
              size="small"
              disabled={v.id == props.value ? true : false}
            >
              {v.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </ThemeProvider>
  );
};

export const SingleDate = (props) => {
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            height: "50px",
            "& .MuiInputBase-root": {
              borderRadius: "30px", // Set the border radius for the input field
              height: "50px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C", // Set the border color for the input field
              height: "50px",
            },
            "& .MuiFormLabel-root": {
              color: "#66023C", // Set the color for the label
              height: "50px",
            },
            "& .MuiInputBase-input": {
              color: "#66023C", // Set the color for the input text
            },
            "& .MuiPickersDay-daySelected": {
              backgroundColor: "#66023C", // Change the background color for the selected date
              "&:hover": {
                backgroundColor: "#66023C", // Change the background color for selected days on hover
              },
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          height: "50px",
          root: {
            "&.Mui-selected": {
              backgroundColor: "#66023C", // Set the background color for the selected date
              color: "white", // Set the text color for the selected date
            },
            "& .MuiButtonBase-root": {
              backgroundColor: "#66023C", // Set the background color for the selected date
              color: "white",
            },
          },
        },
      },
    },
  });
  return (
    <FormControl
      fullWidth={true}
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <DatePicker
            disableToolbar
            variant="inline"
            // slotProps={{
            //   textField: { variant: 'standard', }
            // }}
            format={props.format ? props.format : "dd/MM/yyyy"}
            margin="normal"
            id={props.id}
            name={props.id}
            label={props.label}
            value={props.value}
            sx={{
              width: "100%",
            }}
            onChange={(val) => {
              props.onChange({ target: { id: props.id, value: val } });
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={props.disabled ? true : false}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </FormControl>
  );
};

export const SingleDateTime = (props) => {
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              borderRadius: "30px", // Set the border radius for the input field
              height: "50px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#66023C", // Set the border color for the input field
              height: "50px",
            },
            "& .MuiFormLabel-root": {
              // color: '#66023C', // Set the color for the label
              height: "50px",
            },
            "& .MuiInputBase-input": {
              color: "#66023C", // Set the color for the input text
            },
            "& .MuiPickersDay-daySelected": {
              backgroundColor: "#66023C", // Change the background color for the selected date
              "&:hover": {
                backgroundColor: "#66023C", // Change the background color for selected days on hover
              },
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#66023C", // Set the background color for the selected date
              color: "white", // Set the text color for the selected date
            },
            "& .MuiButtonBase-root": {
              backgroundColor: "#66023C", // Set the background color for the selected date
              color: "white",
            },
          },
        },
      },
    },
  });
  return (
    <FormControl
      fullWidth={true}
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <DatePicker
            disableToolbar
            variant="inline"
            // slotProps={{
            //   textField: { variant: 'standard', }
            // }}
            format={props.format ? props.format : "dd/MM/yyyy hh:mm aa"}
            margin="normal"
            id={props.id}
            name={props.id}
            label={props.label}
            value={props.value}
            sx={{
              width: "100%",
            }}
            onChange={(val) => {
              props.onChange({ target: { id: props.id, value: val } });
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={props.disabled ? true : false}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </FormControl>
  );
};

export const CustomRadio = (props) => {
  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "#650E3D",
            fontFamily: "Poppins",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#650E3D",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          },
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            color: "#650E3D",
            "&.Mui-checked": {
              color: "#650E3D",
            },
          },
        },
      },
    },
  });

  return (
    <FormControl>
      <ThemeProvider theme={theme}>
        <FormLabel component="legend">{props.label}</FormLabel>

        <RadioGroup
          row
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          id={props.id}
        >
          {props.options.map((v, index) => {
            return (
              <FormControlLabel
                key={index}
                value={v.value}
                control={<Radio />}
                label={v.label}
              />
            );
          })}
        </RadioGroup>
      </ThemeProvider>
    </FormControl>
  );
};
