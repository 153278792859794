import React from "react";
import CustomCarousel from "../../components/carousel";
import styles from "../../styles/General.module.css";
import { useTranslation } from "react-i18next";
const YouLearn = () => {
  const { t, i18n } = useTranslation();
  const data = [
    {
      id: 1,
      title: "Discover Legal AI Innovations",
      ar_title: "الذكاء الاصطناعي القانونية",
      description:
        "Uncover the latest AI technologies that are redefining the legal industry.",
      ar_description:
        "اكتشف أحدث تقنيات الذكاء الاصطناعي التي تعيد تعريف الصناعة القانونية.",
    },
    {
      id: 2,
      title: "Boost Your Practice",
      ar_title: "طوّر مهنتك",
      description:
        "Learn how to leverage AI for increased productivity and efficiency.",
      ar_description:
        "تعرف على كيفية الاستفادة من الذكاء الاصطناعي لزيادة الإنتاجية والكفاءة",
    },
    {
      id: 3,
      title: "Stay Ahead of The Curve",
      ar_title: "كن رائد",
      description:
        "Position yourself as an AI-savvy lawyer and gain a competitive edge.",
      ar_description: "اكتسب ميزة تنافسية و براعة في الذكاء الإصطناعي",
    },

    {
      id: 4,
      title: "Witness the Launch of MyHAQQ",
      ar_title: 'اطلاق "حقّي"',
      description:
        "Enjoy exclusive rights on the MyHAQQ Virtual Legal Platform.",
      ar_description: "تمتع بحقوق حصرية على المنصة القانونية الرقمية حقّي.",
    },
    {
      id: 5,
      title: "Expand Your Legal Network",
      ar_title: "وسّع شبكتك القانونية",
      description:
        " Connect with fellow legal professionals and AI experts from the GCC.",
      ar_description:
        "واصل مع زملائك المهنيين القانونيين وخبراء الذكاء الاصطناعي من دول مجلس التعاون الخليجي.",
    },

    {
      id: 6,
      title: "Share Your Experience With Other Legal Professionals",
      ar_title: "شارك تجربتك مع متخصصين قانونيين آخرين",
      description:
        "Gain invaluable insights from expert keynote speakers and panel discussions.",
      ar_description:
        "احصل على رؤى لا تقدر بثمن من المتحدثين الرئيسيين الخبراء وحلقات النقاش.",
    },
  ];

  return (
    <div className={styles.youLearn}>
      <h1>{t("learn")}</h1>
      <CustomCarousel
        slidesToShow={i18n.language.includes("en") ? 2.2 : 2.4}
        data={data}
        en={i18n.language.includes("en")}
      />
    </div>
  );
};

export default YouLearn;
